<template>
  <el-form label-width="120px">
    <div class="flex-item-form-header">
      <div class="content">运费规则</div>
    </div>
    <el-form-item label="运费模板名称" class="form-item">
      <span class="color-">{{baseGoodsInfo.freightTemplateName}}</span>
    </el-form-item>
    <el-form-item label="运费模板ID" class="form-item">
      <span class="color-">{{baseGoodsInfo.freightTemplateId}}</span>
    </el-form-item>
    <div class="flex-item">
      <el-form-item label="体积" class="form-item">
        <span class="color-">{{baseGoodsInfo.calculateVolume}} <sub>m³</sub></span>
      </el-form-item>
      <el-form-item label="重量" class="form-item">
        <span class="color-">{{baseGoodsInfo.calculateWeight}} <sub>kg</sub></span>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
  import {lookGoodsDetailsChildrenBase} from './lookGoodsDetailsChildrenBase'
  export default {
    name: 'goodsDetailsFreight',
    mixins:[lookGoodsDetailsChildrenBase],
  }
</script>

<style scoped lang="less">

</style>
