<template>
  <el-form label-width="120px">
    <div class="flex-item-form-header">
      <div class="content">品牌</div>
    </div>
    <el-form-item label="品牌名称" class="form-item">
      <span class="color-">{{baseGoodsInfo.brandName}}</span>
    </el-form-item>
    <el-form-item label="品牌ID" class="form-item">
      <span class="color-">{{baseGoodsInfo.brandId}}</span>
    </el-form-item>
    <el-form-item label="品牌logo" class="form-item">
      <img :src="baseGoodsInfo.brandLogo" v-if="baseGoodsInfo.brandLogo" class="table-row-image-60">
    </el-form-item>
  </el-form>
</template>

<script>
  import {lookGoodsDetailsChildrenBase} from './lookGoodsDetailsChildrenBase'
  export default {
    name: 'goodsDetailsBrand',
    mixins:[lookGoodsDetailsChildrenBase],
  }
</script>

<style scoped lang="less">

</style>
