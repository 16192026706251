import {isEmpty,JSONParseImages,parseTime} from '../../../assets/js/utils'
import {isWhether,goodsManufactureType,goodsReviewStatus,supportRefund,isDeleteStatus,saleStatus} from '../../../assets/js/status'
import {returnBaseDataGoodsDetails} from '../../../assets/js/modules/goods/goodsDetails'
export let lookGoodsDetailsChildrenBase = {
  props:{
    data:{
      type:Object,
      default:function () {
        return returnBaseDataGoodsDetails()
      },
    },
  },
  data(){
    return {
      supportRefund,
      goodsReviewStatus,
      goodsManufactureType,
      isDeleteStatus,
      isWhether,
      saleStatus,
    }
  },
  methods:{
    isEmpty,
  },
  computed:{
    baseGoodsInfo(){
      return this.data.baseGoodsInfo||{}
    },
    slidesImages: function () {
      let images = this.baseGoodsInfo.banners;
      let list = JSONParseImages(images);
      return list
    },
    detailsImages:function(){
      let images = this.baseGoodsInfo.detailsImages;
      let list = JSONParseImages(images);
      return list
    },
    refundExplainImages:function(){
      let images = this.baseGoodsInfo.refundExplainImages;
      let list = JSONParseImages(images);
      return list
    },
    createTime:function(){
      let time = this.baseGoodsInfo.createTime
      if(time){
        time = parseTime(time);
      }
      return time;
    },
    updateTime:function(){
      let time = this.baseGoodsInfo.updateTime
      if(time){
        time = parseTime(time);
      }
      return time;
    },
  }
}
