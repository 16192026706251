import {templateElDrawer} from '../template/template-el-drawer'

import goodsDetailsBase from './goodsDetailsBase'
import goodsDetailsBrand from './goodsDetailsBrand'
import goodsDetailsFreight from './goodsDetailsFreight'
import goodsDetailsSlides from './goodsDetailsSlides'
import goodsDetailsContent from './goodsDetailsContent'
import goodsDetailsAttribute from './goodsDetailsAttribute'
import goodsDetailsSku from './goodsDetailsSku'
import goodsDetailsRefundExplain from './goodsDetailsRefundExplain'

export let lookGoodsDetailsBase = {
  mixins:[templateElDrawer],
  components:{
    goodsDetailsBase,
    goodsDetailsBrand,
    goodsDetailsFreight,
    goodsDetailsSlides,
    goodsDetailsContent,
    goodsDetailsAttribute,
    goodsDetailsSku,
    goodsDetailsRefundExplain,
  },
  props:{
    customClass:{
      type:String,
      default:'drawer-scroll-y',
    },
  },
  data(){
    return {
      viewTypeList:[
        { name:'基本信息', id:'base',components:goodsDetailsBase },
        { name:'品牌信息', id:'brand',components:goodsDetailsBrand },
        { name:'运费规则', id:'freight',components:goodsDetailsFreight },
        { name:'封面/海报/轮播', id:'banner',components:goodsDetailsSlides},
        { name:'图文详情', id:'details',components:goodsDetailsContent},
        { name:'搜索/属性', id:'attribute', components:goodsDetailsAttribute},
        { name:'SKU', id:'sku', components:goodsDetailsSku},
        { name:'售后须知', id:'refundExplain', components:goodsDetailsRefundExplain},
      ],
      viewTypeListValue:'base',
    }
  },
  created() {

  },
  mounted () {

  },
  methods:{
    handleClickViewType(){

    },
  },
  watch:{
    data(newVal){
      this.viewTypeListValue = 'base';
    }
  }
}
