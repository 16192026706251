<template>
  <el-form label-width="120px">
    <div class="flex-item-form-header">
      <div class="content">封面</div>
    </div>
    <el-form-item label="" class="form-item">
      <img class="goods-main-cover" :src="data.baseGoodsInfo.cover"/>
    </el-form-item>
    <div class="flex-item-form-header">
      <div class="content">海报</div>
    </div>
    <el-form-item label="" class="form-item">
      <img class="goods-main-cover" :src="data.baseGoodsInfo.posterImg"/>
    </el-form-item>
    <div class="flex-item-form-header">
      <div class="content">
        轮播图（{{slidesImages.length}}张）
        <el-button size="mini" plain icon="el-icon-files"
                   @click="triggerSlidesType"
                   v-if="showSlidesType===1">list展示</el-button>
        <el-button size="mini" plain icon="el-icon-picture"
                   @click="triggerSlidesType"
                   v-else>slides展示</el-button>
      </div>
    </div>
    <el-form-item label="" class="form-item" v-if="showSlidesType === 1">
      <div class="goods-slides" >
        <el-carousel height="300px;">
          <el-carousel-item v-for="item in slidesImages" :key="item">
            <img class="slide-image" :src="item"/>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-form-item>
    <template v-else>
      <el-form-item  label="" class="form-item" v-for="(item,index) in slidesImages" :key="item">
        <span slot="label">第{{index + 1}}张</span>
        <img class="goods-main-cover" :src="item"/>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
  import {lookGoodsDetailsChildrenBase} from './lookGoodsDetailsChildrenBase'
  export default {
    name: 'goodsDetailsSlides',
    mixins:[lookGoodsDetailsChildrenBase],
    props:{
      slidesType:{ // 轮播图显示类型 1- 轮播，2-图片列表
        type:Number,
        default:1
      },
    },
    created(){
      if(this.slidesType && this.slidesType !== this.showSlidesType) this.showSlidesType = this.slidesType
    },
    data(){
      return {
        showSlidesType:1,
      }
    },
    methods:{
      triggerSlidesType(){
        this.showSlidesType = this.showSlidesType === 1 ? 2 :1;
      }
    },
    watch:{
      slidesType(newVal){
        this.showSlidesType = newVal
      },
      showSlidesType(newVal){
        this.$emit('update:slidesType',newVal);
      },
    },
  }
</script>

<style scoped lang="less">
  @import (reference) "../../../assets/css/data";
  .goods-slides,
  .goods-main-cover{
    display: block;
    width: 300px;
    border: @border-width-solid solid @border-color-one;
  }
  .goods-slides{
    height: 300px;
  }
  .slide-image{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .goods-main-cover{
    height: auto;
  }
</style>
