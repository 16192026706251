<template>
  <el-form label-width="120px">
    <div class="flex-item-form-header">
      <div class="content">商品信息</div>
    </div>
    <el-form-item label="商品ID" class="form-item">
      <span class="color-">{{baseGoodsInfo.id}}</span>
    </el-form-item>
    <el-form-item label="商品名称" class="form-item">
      <span class="color-">{{baseGoodsInfo.name}}</span>
    </el-form-item>
    <el-form-item label="商品简介" class="form-item">
      <span class="color-">{{baseGoodsInfo.brief}}</span>
    </el-form-item>
    <el-form-item label="商品类型" class="form-item">
      <span class="color-">{{goodsManufactureType[baseGoodsInfo.type]}}</span>
    </el-form-item>

    <el-form-item label="基础分类" class="form-item">
      <span class="color-">{{baseGoodsInfo.cateDetails}}</span>
    </el-form-item>

    <div class="flex-item-form-header">
      <div class="content">其他</div>
    </div>
    <el-form-item label="支持退换货" class="form-item">
      <span class="color-">{{supportRefund[baseGoodsInfo.supportRefund]}}</span>
    </el-form-item>
    <el-form-item label="状态" class="form-item">
      <span class="color-">{{saleStatus[baseGoodsInfo.status]}}</span>
    </el-form-item>
    <el-form-item label="是否被删除" class="form-item">
      <span class="color-">{{isDeleteStatus[baseGoodsInfo.isDelete]}}</span>
    </el-form-item>
    <el-form-item label="创建时间" class="form-item">
      <span class="color-">{{createTime}}</span>
    </el-form-item>
    <el-form-item label="更新时间" class="form-item">
      <span class="color-">{{updateTime}}</span>
    </el-form-item>

    <template v-if="data.reviewStatus">
      <div class="flex-item-form-header">
        <div class="content">审核记录</div>
      </div>
      <el-form-item label="审核状态" class="form-item">
        <span class="color-">{{goodsReviewStatus[data.reviewStatus]}}</span>
      </el-form-item>
      <el-form-item label="审核备注" class="form-item">
        <span class="color-">{{data.reviewRemark}}</span>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
  import {lookGoodsDetailsChildrenBase} from './lookGoodsDetailsChildrenBase'
  export default {
    name: 'goodsDetailsBase',
    mixins:[lookGoodsDetailsChildrenBase],
  }
</script>

<style scoped lang="less">

</style>
