<template>
  <el-form label-width="0px">
    <div class="flex-item-form-header">
      <div class="content">售后须知</div>
    </div>
    <el-form-item label="" class="form-item">
      <div class="goods-details">
        <template v-if="baseGoodsInfo.refundExplainText || refundExplainImages.length">
          <pre style="white-space: normal">{{baseGoodsInfo.refundExplainText}}</pre>
          <img class="img" :src="item" v-for="item in refundExplainImages"/>
        </template>
        <template v-else>
          <p>暂无内容</p>
        </template>
      </div>
    </el-form-item>



  </el-form>
</template>

<script>
  import {lookGoodsDetailsChildrenBase} from './lookGoodsDetailsChildrenBase'
  export default {
    name: 'goodsDetailsRefundExplain',
    mixins:[lookGoodsDetailsChildrenBase],
  }
</script>

<style scoped lang="less">
  @import (reference) "../../../assets/css/data";
  .goods-details{
    width: 600px;
    max-width: 100%;
    border: @border-width-solid solid @border-color-one;
    padding: @padding-primary;
    box-sizing: border-box;
    img{
      width: 100%;
    }
  }

</style>
