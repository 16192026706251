<template>
  <el-form label-width="0px">
    <!--<div class="flex-item-form-header">-->
      <!--<div class="content">SKU</div>-->
    <!--</div>-->
    <el-table
      class="margin-bottom"
      :data="data.goodsSkuList"
      border
      max-height="600"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="sku"
        width="100" >
      </el-table-column>
      <el-table-column
        prop="stockNum"
        label="库存"
        width="100">
      </el-table-column>
      <el-table-column
        prop="purchasePrice"
        label="供货成本价"
        width="100"
        v-if="showPoint">
      </el-table-column>
      <el-table-column
        prop="marketPrice"
        label="市场价"
        width="100">
      </el-table-column>
      <el-table-column
        prop="price"
        label="售价"
        width="100">
      </el-table-column>
      <el-table-column
        prop="cost"
        label="扣点金额"
        width="100"
        v-if="showPoint">
      </el-table-column>
      <el-table-column
        prop="cost"
        label="扣点"
        width="100"
        v-if="showPoint">
        <template slot-scope="scope">
          {{scope.row.costPercent||0}}%
        </template>
      </el-table-column>
      <el-table-column
        label="图片"
        width="100">
        <template slot-scope="scope">
          <img v-if="scope.row.cover" :src="scope.row.cover" alt="sku logo" class="table-row-image-60" />
        </template>
      </el-table-column>
      <el-table-column>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
  import {lookGoodsDetailsChildrenBase} from './lookGoodsDetailsChildrenBase'
  import {mapState} from 'vuex'
  export default {
    name: 'goodsDetailsSku',
    mixins:[lookGoodsDetailsChildrenBase],
    computed:{
      ...mapState({
        showPoint:state=>state.userInfo.showCostAndPurchase,
      }),
    },
  }
</script>

<style scoped lang="less">

</style>
