<template>
  <el-form label-width="120px">
    <div class="flex-item-form-header">
      <div class="content">搜索关键词</div>
    </div>
    <el-form-item label="搜索关键词" class="form-item" >
      <span class="color-">{{baseGoodsInfo.keywords}}</span>
    </el-form-item>
    <div class="flex-item-form-header">
      <div class="content">非销售属性</div>
    </div>
    <template v-if="data.goodsAttributeList.length">
      <el-form-item v-for="item in data.goodsAttributeList" :key="item.name">
        <template slot="label"><span class="">{{item.name}}：</span></template>
        <span class="color-info" v-for="v in item.attrValList">
        {{v.value}}
      </span>
      </el-form-item>
    </template>
    <template v-else>
      <el-form-item label="" label-width="40px" class="form-item" >
        <span class="color-info">暂无非销售属性</span>
      </el-form-item>
    </template>


  </el-form>
</template>

<script>
  import {lookGoodsDetailsChildrenBase} from './lookGoodsDetailsChildrenBase'
  export default {
    name: 'goodsDetailsAttribute',
    mixins:[lookGoodsDetailsChildrenBase],
  }
</script>

<style scoped lang="less">

</style>
