<template>
  <el-drawer :title="title"
             :visible.sync="visibleValue"
             :custom-class="customClass"
             :append-to-body="appendToBody"
             :before-close="beforeClose"
             :close-on-press-escape="closeOnPressEscape"
             :wrapperClosable="wrapperClosable"
             :size="size">
    <el-tabs slot="title"
             class="style-init"
             v-model="viewTypeListValue"
             @tab-click="handleClickViewType">
      <el-tab-pane v-for="item in viewTypeList"
                   :key="item.name"
                   :label="item.name"
                   :name="item.id" ></el-tab-pane>
    </el-tabs>
    <div class="drawer-scroll-y-box">
      <template v-for="item in viewTypeList">
        <keep-alive>
          <template v-if="item.id === viewTypeListValue">
            <component :is="item.components" :data="data"></component>
          </template>
        </keep-alive>
      </template>
    </div>
  </el-drawer>
</template>

<script>
  import {lookGoodsDetailsBase} from './lookGoodsDetailsBase'
  import {returnBaseDataGoodsDetails} from '../../../assets/js/modules/goods/goodsDetails'
  export default {
    name: 'lookGoodsDetails',
    mixins:[lookGoodsDetailsBase],
    components:{

    },
    props:{
      data:{
        type:Object,
        default:function () {
          return returnBaseDataGoodsDetails()
        },
      },
    },
    data(){
      return {

      }
    },
    created() {

    },
    mounted () {

    },
    methods:{

    },
    watch:{

    }
  }
</script>

<style scoped>

</style>
